.title {
    margin: 24px;
    bottom: 0;
    position: fixed;
    width: calc(100% - 48px);
    font-family: Montserrat, Helvetica, Arial, sans-serif;
  }
  .title__heading {
    margin: 0;
    font-size: 44px;
    font-weight: 600;
    letter-spacing: -1px;
  }
  .title__sub {
    margin: 0;
    font-size: 30px;
    max-width: 600px;
    font-weight: 300;
  }
  .title__contact {
    margin-top: 48px;
  }
  
  .title__contact a {
    color: #000;
    font-weight: 400;
    text-decoration: none;
  }
  .title__contact a:hover {
    border-bottom: 1px solid #000;
  }
  
  .title__contact a:not(:first-child) {
    margin-left: 8px;
  }
  
  .title__contact a:not(:last-child)::after {
    content: '/';
    padding-left: 8px;
    border-bottom: 1px solid #FFF;
  }